import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MenuPage } from 'core/components/side-menu-occluded/menu-page';
import { MenuPages } from 'core/components/side-menu-occluded/menu-pages';
import { PageService } from 'core/shared/page.service';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Component({
    selector: 'side-menu-items',
    templateUrl: 'side-menu-items.component.html',
    styleUrls: ['side-menu-items.component.scss']
})
export class SideMenuItems implements OnInit {
    public subPageOpen = false;
    public managementPages = new MenuPages;
    public pageSelected = null;

    constructor(
        private pageService: PageService,
        private changeDetectorRef: ChangeDetectorRef,
        private menuController: MenuController,
        public auth: AuthService
    ) { }

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();
        this.pageService.toggleDetails$.subscribe(data => {
            const { page, event } = data;
            this.toggleDetails(page, event);
        });
    }

    public toggleDetails(page, event) {
        this.pageSelected = page

    }

    public togglePage(page, event) {

        this.stopMenu(event)
        this.pageService.triggerToggleDetails(page, event);
    }

    stopMenu(event: Event) {
        event.stopPropagation();
    }

    closeMenu() {
        this.menuController.close('main-menu')
    }

    
}

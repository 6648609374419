import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent<T> implements OnInit, OnChanges {

    @Input() images: string[] = [];

    @Input() rotation: boolean = false;

    @Input() timeRotation: number = 5000;

    public currentIndex = 0;
    private intervalId: any;

    ngOnInit(): void {
        this.currentIndex = 0;

        if (!this.images?.length) {
            return;
        }

        this.startImageRotation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.images) {
            this.startImageRotation();
        }
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    public nextImage() {
        if (this.currentIndex < this.images.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
    }

    public prevImage() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.images.length - 1;
        }
    }

    private startImageRotation() {
        if (this.rotation !== true) {
            return;
        }

        var self = this;
        this.intervalId = setInterval(() => self.nextImage(), this.timeRotation);
    }

}

export { }

declare global {
    interface Array<T> {
        unique(): Array<T>;
    }

    interface String {
        toSnakeCase(): string;
    }
}

Array.prototype.unique = function() {
    return [...new Set(this)];
}

String.prototype.toSnakeCase = function () {
    return this.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

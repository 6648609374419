import { Injectable } from '@angular/core';
import { User } from 'pages/account/users/shared/user.model';
import { UserService } from 'pages/account/users/shared/user.service';
import { BaseResponse } from 'core/shared/base-response.interface';
import { SessionService } from 'pages/account/auth/shared/session-service';
import { AuthHttpService } from 'pages/account/auth/shared/auth-http.service';
import { PermissionService } from 'pages/account/permissions/shared/permission.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public user: User = null;

    public missingPermissions: string[] = [];

    constructor(
        private http: AuthHttpService,
        private userService: UserService,
        public session: SessionService,
        private permissions: PermissionService
    ) { }

    public async login(email, password): Promise<boolean> {
        return this.http.authenticate(email, password)
            .then((response: BaseResponse) => {
                this.session.setCookie(response.data.token);
                return this.setUser(new User(response.data.user));
            });
    }

    public async logout(): Promise<boolean> {
        return this.http.logOut()
            .then(() => {
                this.killSession();

                return true;
            })
            .catch(() => false);
    }

    public async checkAuth(): Promise<boolean> {
        if (!this.session.isValid) {
            this.session.deleteCookie();
            return false;
        }

        if (!this.user) {
            return await this.getAuthenticatedUser();
        }

        return true;
    }

    /**
     * Verifica se o usuário tem a(s) permissão(ões) informada(s)
    */
    public userCan(permissions: Array<string | Array<string | Array<string>>>): boolean {
        return this.permissions.userCan(this.user, permissions);
    }

    private getAuthenticatedUser(): Promise<boolean> {
        return this.userService.me().then(
            (user: User) => {
                this.setUser(user);
                return true;
            }
        );
    }

    private setUser(user: User): boolean {
        this.user = user;

        return true;
    }

    public killSession() {
        this.session.deleteCookie();
        this.unsetUser();
    }

    private unsetUser() {
        this.user = null;
    }
}

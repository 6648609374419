import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MenuPage } from 'core/components/side-menu-occluded/menu-page';
import { MenuPages } from 'core/components/side-menu-occluded/menu-pages';
import { PageService } from 'core/shared/page.service';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Component({
    selector: 'side-menu-occluded',
    templateUrl: 'side-menu-occluded.component.html',
    styleUrls: ['side-menu-occluded.component.scss']
})
export class SideMenuOccluded implements OnInit {
    public subPageOpen = false;
    public managementPages = new MenuPages;
    public pageSelected = null;
    public eventSelected = null;

    constructor(
        private pageService: PageService,
        private changeDetectorRef: ChangeDetectorRef,
        public auth: AuthService,
        private menuController: MenuController
    ) { }

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();
        this.pageService.triggerToggleDetails(null, null);
    }

    public toggleDetails(page, event) {

        this.pageSelected = page;
        this.eventSelected = event;
        this.pageService.triggerToggleDetails(this.pageSelected, this.eventSelected);

        this.subPageOpen = !this.subPageOpen


        if (event) {
            event.preventDefault();
        }

        this.managementPages.pages.forEach(page => {
            if (page.title === 'Finanças') {
                page.subPages.forEach(subPage => {
                    if (subPage.title == 'Previsões de Pagamento') {
                        subPage.url = this.auth.user.profiles.some(
                            profile => profile.name === 'Previsões de Pagamento (Admin)')
                            ? '/payment-forecasts'
                            : '/payment-forecasts/list'

                    }
                })
            }
        })

        this.managementPages.pages.forEach(p => {
            if (p.title !== page.title) {
                p.showDetails = false;
            }
        });
        this.subPageOpen = !this.subPageOpen
        page.toggle();
    }

    stopMenu(event: Event) {
        event.stopPropagation();
    }

    openMenu() {
		this.menuController.open('main-menu')
	}
    
}

import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDocumentMask][ngModel]',
})
export class DocumentMaskDirective implements OnChanges {
  @Input() ngModel: string;

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const cleanValue = input.value.replace(/[^0-9]/g, '');

    let formattedValue = '';
    if (cleanValue.length <= 11) {
      formattedValue = this.formatPartialCPF(cleanValue);
    } else if (cleanValue.length <= 14) {
      formattedValue = this.formatPartialCNPJ(cleanValue);
    }

    if (formattedValue) {
      this.control.control?.setValue(formattedValue);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ngModel'] && this.control.control) {
      const formattedValue = this.formatValue(changes['ngModel'].currentValue);
      this.control.control.setValue(formattedValue);
    }
  }

  private formatValue(value: string): string {
    if (!value) {
      return ''; // Handle empty value
    }

    const cleanValue = value.replace(/[^0-9]/g, '');

    // Logic to format based on value length
    if (cleanValue.length <= 11) {
      return this.formatPartialCPF(cleanValue);
    } else if (cleanValue.length <= 14) {
      return this.formatPartialCNPJ(cleanValue);
    } else {
      return value; // Return unformatted value if exceeding length
    }
  }

  private formatPartialCPF(cpf: string): string {
    return cpf
      .replace(/^(\d{3})(\d{0,3})/, '$1.$2')
      .replace(/^(\d{3}\.\d{3})(\d{0,3})/, '$1.$2')
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d{0,2})/, '$1-$2');
  }

  private formatPartialCNPJ(cnpj: string): string {
    return cnpj
      .replace(/^(\d{2})(\d{0,3})/, '$1.$2')
      .replace(/^(\d{2}\.\d{3})(\d{0,3})/, '$1.$2')
      .replace(/^(\d{2}\.\d{3}\.\d{3})(\d{0,4})/, '$1/$2')
      .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d{0,2})/, '$1-$2');
  }
}

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'text-modal',
  templateUrl: './text-modal.component.html'
})
export class TextModalComponent {

    @Input() public text?: string  = null;
    @Input() public title?: string = 'Texto';
    @Input() public label?: string = 'Texto';

    constructor(
        private modal: ModalController
    ) {}

    public async dismiss(): Promise<boolean> {
        return this.modal.dismiss(null);
    }

    public async save(): Promise<boolean> {
        return this.modal.dismiss(this.text);
    }
}

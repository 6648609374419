import { MenuPage } from 'core/components/side-menu-occluded/menu-page';

export class MenuPages {
    public pages: MenuPage[] = [
        new MenuPage({
            title: 'Gestão Comercial',
            url: '/management/commercial',
            icon: 'people',
            showDetails: false,
            subPages: [
                {
                    title: 'Pessoas',
                    url: '/people',
                    icon: 'person-circle',
                    permission: 'people',
                    showDetails: false,
                },
                {
                    title: 'Equipes Comerciais',
                    url: '/sales-teams',
                    icon: 'people-circle',
                    permission: 'sales_teams',
                    showDetails: false,
                },
                {
                    title: 'Ações Corretivas',
                    url: '/corrective-actions',
                    icon: 'hand-right',
                    permission: 'corrective_actions',
                    showDetails: false,
                },
                {
                    title: 'Treinamentos',
                    url: '/corrective-actions/checklist-item-categories',
                    icon: 'ribbon',
                    permission: 'corrective_actions',
                    showDetails: false,
                },
                {
                    title: 'Formulários',
                    url: '/surveys',
                    icon: 'list',
                    permission: 'surveys',
                    showDetails: false,
                },
                {
                    title: 'Movimentações de Pessoas',
                    url: '/people/movement-logs',
                    icon: 'swap-horizontal',
                    permission: 'movement_logs',
                },
                {
                    title: 'Metas de Performance',
                    url: '/performance-goals',
                    icon: 'analytics',
                    permission: 'performance_goals',
                },
                {
                    title: 'Classificações do Termômetro',
                    url: '/thermometer/classifications',
                    icon: 'thermometer',
                    permission: 'external_stages',
                },
                {
                    title: 'Busca de Leads',
                    url: '/leads',
                    icon: 'list-circle',
                    permission: 'leads',
                },
                {
                    title: 'Tabela de Preço',
                    url: '/products/price-table-report',
                    icon: 'pricetags',
                    permission: 'products',
                },
                {
                    title: 'Relatórios',
                    url: '/reports',
                    icon: 'receipt',
                    permission: 'surveys',
                },
            ],
        }),
        new MenuPage({
            title: 'Gestão de Ativos',
            url: '/asset-management',
            icon: 'calculator',
            default: true,
            showDetails: false,
            subPages: [
                {
                    title: 'Pré-Cadastros',
                    url: '/asset-management/asset-pre-registrations',
                    icon: 'cube',
                    showDetails: false,
                },
                {
                    title: 'Transferências',
                    url: '/asset-management/asset-transfers',
                    icon: 'swap-horizontal',
                    showDetails: false,
                },
                {
                    title: 'Baixas',
                    url: '/asset-management/asset-write-off-requests',
                    icon: 'archive',
                    showDetails: false,
                },
                {
                    title: 'Relatórios',
                    url: '/asset-management/reports',
                    icon: 'list',
                    showDetails: false,
                    permission: 'asset_management_reports',
                },
            ],
        }),
        new MenuPage({
            title: 'Finanças',
            url: '/finances',
            icon: 'cash',
            permission: 'people',
            showDetails: false,
            subPages:[
                {
                    title:'Contratos em Aprovação',
                    url:'finances/reports/contract-approval',
                    icon: 'checkmark',
                    permission:'people',
                    showDetails: false,
                },
                {
                    title: 'Previsões de Pagamento',
                    url: '/payment-forecasts',
                    icon: 'wallet',
                    showDetails: false,
                },
                {
                    title: 'Relatório de Contas',
                    url: '/bills-report',
                    icon: 'card',
                    permission: 'bills',
                    showDetails: false,
                },
            ]
        }),
        new MenuPage({
            title: 'Automações',
            url: '/automations',
            icon: 'flash',
            default: false,
            showDetails: false,
            subPages: [
                {
                    title: 'Pagamento de IPTU',
                    url: '/iptu-payment',
                    icon: 'folder-open',
                    showDetails: false,
                },
                {
                    title: 'Integração de Clientes',
                    url: '/sca',
                    icon: 'flash',
                    showDetails: false,
                },
                {
                    title: 'Pagamento de Comissões',
                    url: '/cpa',
                    icon: 'flash',
                    showDetails: false,
                },
                {
                    title: 'Validação de Renda',
                    url: '/cia',
                    icon: 'flash',
                    showDetails: false,
                },
            ]
        }),
        new MenuPage({
            title: 'Suprimentos',
            url: '/supply-chain',
            icon: 'cube',
            showDetails: false,
            subPages:[
                {
                    title:'Dashboard',
                    url:'/supply-chain/dashboard',
                    icon:'stats-chart',
                    permission:'economic_indicators',
                    showDetails:false
                },
                {
                    title:'Indicadores Econômicos',
                    url:'/supply-chain/economic-indicators',
                    icon:'diamond',
                    permission:'economic_indicators',
                    showDetails:false
                },
                {
                    title:'Insumos',
                    url:'/supply-chain/supplies',
                    icon:'construct',
                    permission:'supplies',
                    showDetails:false,
                },
            ]

        }),
        new MenuPage({
            title: 'Operações',
            url: '/operations',
            icon: 'easel',
            showDetails: false,
            subPages:[
                {
                    title:'Quadros de Concorrência',
                    url:'/competition-boards',
                    icon:'reader',
                    permission:'competition_boards',
                    showDetails:false
                },
            ]

        }),
        new MenuPage({
            title: 'Configurações',
            url: '/settings',
            icon: 'settings',
            permission: 'people',
            showDetails: false,
            subPages: [
                {
                    title: 'Cidades',
                    url: '/cities',
                    icon: 'location',
                    permission: 'cities',
                    showDetails: false,
                },
                {
                    title: 'Empreendimentos',
                    url: '/products',
                    params: { mode: 'list' },
                    icon: 'business',
                    permission: 'products',
                    showDetails: false,
                },
                {
                    title: 'Cargos',
                    url: '/positions',
                    icon: 'medal',
                    permission: 'positions',
                    showDetails: false,
                },
                {
                    title: 'Áreas',
                    url: '/company-areas',
                    icon: 'people',
                    permission: 'company_areas',
                    showDetails: false,
                },
                {
                    title: 'Empresas',
                    url: '/companies',
                    icon: 'storefront',
                    showDetails: false,
                },
                {
                    title: 'Usuários',
                    url: '/users',
                    icon: 'person',
                    permission: 'users',
                    showDetails: false,
                },
                {
                    title: 'Perfis de Acesso',
                    url: '/profiles',
                    icon: 'key',
                    permission: 'profiles',
                    showDetails: false,
                },
                {
                    title: 'Parâmetros do Sistema',
                    url: '/settings/edit',
                    icon: 'settings',
                    permission: 'settings',
                    showDetails: false,
                },
                {
                    title: 'Fluxos de Aprovações',
                    url: '/approval-flows',
                    icon: 'checkmark',
                    permission: 'approval_flows',
                    showDetails: false,
                },
            ],
        }),
    ];
}

import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthService } from 'pages/account/auth/shared/auth.service';
import { LoaderService } from 'core/shared/loader.service';
import { TitleService } from 'core/shared/title.service';
import { PersonProfile } from 'pages/inputs/people/components/person-profile/person-profile';
import { CreateModal } from 'pages/misc/feedback/create/create.modal';
import { PageService } from 'core/shared/page.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
	public loading: boolean = false;
	public version: string = environment.version;
	public environment: string = environment.environment;
	activeSubpage: any = null;
	activeSubpageData: any = null;
	public pageSelected = null;

	public clique = document.getElementsByClassName("side-menu-minimized");

	constructor(
		public actionSheetController: ActionSheetController,
		public menuController: MenuController,
		public modalController: ModalController,
		public checkupController: ActionSheetController,
		public auth: AuthService,
		public loader: LoaderService,
		public titleService: TitleService,
		public router: Router,
		private pageService: PageService
	) { }

	@ViewChild('menuContainer') menuContainer: ElementRef;
	@ViewChild('sideMenuItems') sideMenuItems: ElementRef;
	@ViewChild('feedbackItem') feedbackItem: ElementRef;

	ngAfterViewChecked() {
		this.removeSplitPlaneClass();
		this.updateLogo();
		this.updateBottomLogo();

		this.pageService.toggleDetails$.subscribe(data => {
            const { page, event } = data;
			this.pageSelected = page
        });
	}

	private updateLogo() {
		const img = document.querySelector('#logo img');
		if (img) {
			const updatedSrc = this.verifyThemeForIcon();
			if (img.getAttribute('src') !== updatedSrc) {
				img.setAttribute('src', updatedSrc);
			}
		}
	}

	private updateBottomLogo() {
		const img = document.querySelector('.splash-img');
		if (img) {
			const updatedSrc = this.verifyThemeForIcon();
			if (img.getAttribute('src') !== updatedSrc) {
				img.setAttribute('src', updatedSrc);
			}
		}
	}

	async showUserProfile(identifier: string) {

		const modal = await this.modalController.create({
			component: PersonProfile,
			cssClass: 'person-profile-modal',
			componentProps: {
				id: identifier
			}
		});
		await modal.present();
	}

	async feedbackForm() {
		const modal = await this.modalController.create({
			component: CreateModal,
			cssClass: 'compact-modal feedback-modal'
		});

		await modal.present();
	}

	verifyThemeForIcon(): string {
		return document.body.getAttribute('color-theme') === 'light'
			? '../../assets/logo-prestes-light.png' :
			'../../assets/logo-prestes-dark.png';
	}

	removeSplitPlaneClass() {
		this.menuContainer.nativeElement.classList.remove('split-pane-side');
	}

	displaySubpage(subPage) {
		this.activeSubpage = subPage;
		this.activeSubpageData = subPage.data;
	}

	openMenu() {
		this.pageService.triggerToggleDetails(null, null);
		this.menuController.open('main-menu')
		
	}

	excludePageSelector(){
		this.pageSelected = null	
	}

}


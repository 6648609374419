import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { RouteProgressBar } from 'core/components/route-progress-bar/route-progress-bar.component';
import { IonBaseSearch } from 'core/components/ion-base-search/ion-base-search.component';
import { PrestesCardComponent } from 'core/components/prestes-card/prestes-card.component';
import { TooltipDirective } from 'core/directives/tooltip/tooltip.directive';
import { IndexPage } from 'core/components/pages/index/index.page';
import { BasePage } from 'core/components/pages/base/base.page';
import { ScrollbarThemeModule } from 'core/directives/scrollbar-theme.directive';
import { SideMenuOccluded } from 'core/components/side-menu-occluded/side-menu-occluded.component';
import { SideMenuItems } from 'core/components/side-menu-items/side-menu-items.component';
import { RouterModule } from '@angular/router';
import { DroppableFileInput } from 'core/components/droppable-file-input/droppable-file-input.component';
import { DroppableDirective } from 'core/directives/droppable.directive';
import { FileCard } from 'core/components/file-card/file-card.component';
import { CurrencyMaskDirective } from 'core/directives/currency-mask.directive';
import { PlateMaskDirective } from 'core/directives/plate-mask.directive';
import { MaskedInputComponent } from 'core/components/masked-input/masked-input.component';
import { MultipleDroppableFileInput } from 'core/components/multiple-droppable-file-input/multiple-droppable-file-input.component';
import { TooltipComponent } from 'core/components/tooltip/tooltip.component';
import { InfoTooltipDirective } from 'core/directives/info-tooltip/info-tooltip.directive';
import { PermissionModule } from 'pages/account/permissions/shared/permission.module';
import { TroncudoTableComponent } from 'core/components/troncudo-table/troncudo-table';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerComponent } from 'core/components/date-picker/date-picker.component';
import { LocalDateParserFormatter } from 'core/components/date-picker/formatter/local-date-parser.formatter';
import { LocalDateAdapter } from 'core/components/date-picker/formatter/local-date-adapter.formatter';
import { PercentMaskDirective } from 'core/directives/percent-mask.directive';
import { TextModalComponent } from 'core/components/text-modal/text-modal.component';
import { DocumentMaskDirective } from 'core/directives/document.directive';


@NgModule({
    declarations: [
        RouteProgressBar,
        IonBaseSearch,
        DroppableFileInput,
        MultipleDroppableFileInput,
        FileCard,
        PrestesCardComponent,
        TooltipDirective,
        IndexPage,
        BasePage,
        SideMenuOccluded,
        SideMenuItems,
        DroppableDirective,
        PercentMaskDirective,
        CurrencyMaskDirective,
        DocumentMaskDirective,
        PlateMaskDirective,
        MaskedInputComponent,
        TooltipComponent,
        InfoTooltipDirective,
        TroncudoTableComponent,
        DatePickerComponent,
        TextModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        IonicSelectableModule,
        ScrollbarThemeModule,
        PermissionModule,
        NgbDatepickerModule,
        NgbPaginationModule
    ],
    exports: [
        CommonModule,
        IonicModule,
        NgbPaginationModule,
        RouteProgressBar,
        IonBaseSearch,
        DroppableFileInput,
        MultipleDroppableFileInput,
        FileCard,
        FormsModule,
        PrestesCardComponent,
        TooltipDirective,
        IndexPage,
        BasePage,
        SideMenuOccluded,
        SideMenuItems,
        DroppableDirective,
        CurrencyMaskDirective,
        PlateMaskDirective,
        MaskedInputComponent,
        TooltipComponent,
        InfoTooltipDirective,
        TroncudoTableComponent,
        DatePickerComponent,
        TextModalComponent
    ],
    providers: [
        {provide: NgbDateAdapter, useClass: LocalDateAdapter},
        { provide: NgbDateParserFormatter, useClass: LocalDateParserFormatter }
    ]
})
export class BaseModule {}

export { }

declare global {
    interface Array<T> {
        unique(): Array<T>;
    }

    interface String {
        toSnakeCase(): string;
    }

    interface Object {
        copy<T>(): T;
    }
}

Array.prototype.unique = function() {
    return [...new Set(this)];
}

String.prototype.toSnakeCase = function () {
    return this.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

Object.prototype.copy = function<T>(): T {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
}

import { BaseService } from 'core/shared/base.service';
import { Injectable } from '@angular/core';
import { BaseResponse } from 'core/shared/base-response.interface';
import { Feedback } from 'pages/misc/feedback/shared/feedback.model';
import { FeedbackCategory } from './feedback-category.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService extends BaseService {

    protected url: string = 'api/feedbacks';

    private localFeedbackCategories: FeedbackCategory[] = [];

    public get feedbackCategories() {
        return this.getFeedbackCategories();
    }

    /**
     * `POST /api/feedbacks`
     *
     * Store Feedback
     */
    public async store(feedback: Feedback): Promise<Feedback> {

        const baseResponse = this.handleBaseResponse(
            this.http.post((`${this.apiUrl}/feedbacks`), feedback).toPromise()
        ).then((response: BaseResponse) => new Feedback(response.data.feedback));

        return baseResponse;
    }


    /**
     * `GET /api/feedback-categories`
     *
     * Get FeedbackCategories
     */
    private async getFeedbackCategories(): Promise<FeedbackCategory[]> {
        if (!this.localFeedbackCategories.length) {
            let feedbackCategories = await this.handleBaseResponse(
                this.http.get(`${this.apiUrl}/feedbacks/categories`).toPromise()
            ).then(
                (response: BaseResponse) => response.data.feedbackCategories.map(
                    (feedbackCategory) => new FeedbackCategory(feedbackCategory)
                )
            );

            this.localFeedbackCategories = feedbackCategories;
        }

        return this.localFeedbackCategories;
    }
}

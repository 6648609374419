import { Component, OnInit } from '@angular/core';
import { AuthService } from 'pages/account/auth/shared/auth.service';

@Component({
    templateUrl: './permission-denied.page.html',
})
export class PermissionDeniedPage implements OnInit {
    info: Record<string, string> = {
        title: 'Não autorizado',
        status: '403',
        message: 'Sem autorização',
    };

    constructor(
        public auth: AuthService
    ) {}

    ngOnInit() {}
}

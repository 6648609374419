import { IonCheckbox } from '@ionic/angular';
import { TableConfig, TableColumn, WithOptionalState } from './list.interface';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { Meta } from 'core/shared/meta.interface';

@Component({
    selector: 'list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent<T> implements OnChanges {
    @Input() config: TableConfig;
    @Input() data: WithOptionalState<T>[];
    @Input() loading: boolean = false;
    @Input() pagination: Meta = null;
    @Input() sortField: string = null;
    @Input() sortDirection: 'asc' | 'desc' | 'default' = 'default';
    @Input() scroll: 'scroll' | 'none' = 'none';

    @Output() onSortChange = new EventEmitter<{
        field: string;
        sortDirection: string;
    }>();
    @Output() onSelectionChange = new EventEmitter<WithOptionalState<T>>();
    @Output() onRowClickChange = new EventEmitter<WithOptionalState<T>>();
    @Output() onHoverChange = new EventEmitter<{ item: WithOptionalState<T>; state: boolean }>();
    @Output() onPageChange = new EventEmitter<{ pageNumber: number }>();

    @ViewChildren(IonCheckbox) checkboxes: QueryList<IonCheckbox>;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.data?.length) {
            this.validateSelectable();
        }
    }

    private validateSelectable() {
        if (this.config.selectable && this.data.length) {
            if (!('checked' in this.data[0] && 'hovered' in this.data[0])) {
                console.warn(
                    'Elementos não possuem propriedades checked ou hovered'
                );
            }
        }
    }

    public skeletons: number[] = Array(10)
        .fill(10)
        .map((x, i) => i);

    public getSortIcon(field: string): string {
        if (this.sortField === field) {
            if (this.sortDirection === 'asc') {
                return 'arrow-up';
            }

            if (this.sortDirection === 'desc') {
                return 'arrow-down';
            }
        }
        return 'arrow-forward';
    }

    public sort(column: TableColumn): void {
        if (column.sortable) {
            this.sortField = column.field;
            this.onSortChange.emit({
                field: column.field,
                sortDirection: this.sortDirection,
            });
        }
    }

    public getValue(item: any, field: string): any {
        return field.split('.').reduce((obj, key) => obj?.[key], item);
    }

    public getFormattedValue(item: any, column: TableColumn): string {
        if (column.format) {
            return column.format(item);
        }

        const value = this.getValue(item, column.field);

        if (!value) {
            return '-';
        }

        return value;
    }

    public onCheckboxChange(event: any, item: any): void {
        if (this.config.selectable) {
            item.checked = event.detail.checked;
            this.onSelectionChange.emit(item);
        }
    }

    public onRowClick(item: any): void {
        this.onRowClickChange.emit(item);
    }

    public onHover(item: any, state: boolean): void {
        if (!item.checked) {
            item.hovered = state;
            this.onHoverChange.emit({ item, state });
        }
    }

    public isCheckboxVisible(item: any): boolean {
        if (this.config.selectable) {
            return item.hovered || item.checked;
        }

        return false;
    }

    public emitPageChange(event: any): void {
        this.onPageChange.emit({ pageNumber: event });
    }

    public uncheckAll() {
        if (this.config.selectable) {
            this.checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });
        }
    }
}

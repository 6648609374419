import { Directive, OnDestroy, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy {

    @Input('tooltip') tooltip = ''; // The text for the tooltip to display
    @Input('delay') delay?= 190; // Optional delay input, in ms
    @Input('object') object? = ''

    public x = 0;
    public y = 0;

    private myPopup;
    private timer;

    constructor(private el: ElementRef) { }

    ngOnDestroy(): void {
        if (this.myPopup) { this.myPopup.remove() }
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.timer = setTimeout(() => {

            switch (this.object){
                case '':
                    this.x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
                    this.y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra

                    break
                case 'menu-buttons':
                    const rect = this.el.nativeElement.getBoundingClientRect();
                    this.x = rect.right + 60;
                    this.y = rect.top + (rect.height / 6);

                    break
            }

            this.createTooltipPopup(this.x, this.y);
        }, 0);
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.timer) clearTimeout(this.timer);
        if (this.myPopup) { this.myPopup.remove() }
    }

    private createTooltipPopup(x: number, y: number) {
        let popup = document.createElement('div');

        if (this.tooltip === 'required') {
            var tooltip = 'Este campo é obrigatório';
        } else {
            var tooltip = this.tooltip
        }

        popup.innerHTML = tooltip;
        popup.setAttribute("class", "tooltip-container");
        popup.style.top = y.toString() + "px";
        popup.style.left = x.toString() + "px";

        if (this.object === 'menu-buttons') {
            popup.style.textAlign = "left";
            popup.style.width = "120px";
        }

        document.body.appendChild(popup);
        this.myPopup = popup;

        setTimeout(() => {
            if (this.myPopup) this.myPopup.remove();
        }, 5000); // Remove tooltip after 5 seconds
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { Release } from 'pages/misc/release-notes/shared/release.page';

@Component({
    selector: 'sliding-card',
    templateUrl: './sliding-card.component.html',
    styleUrls: ['./sliding-card.component.scss'],
})
export class SlidingCardComponent<T> implements OnInit {

    @Input() data: any = null;

    @Input() rotation: boolean = false;
    @Input() timeRotation: number = 5000;

    public currentIndex = 0;
    private intervalId: any;

    ngOnInit(): void {
        this.currentIndex = 0;
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    public nextCard() {
        if (this.currentIndex < this.data['modules'].length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
    }

    public prevCard() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.data['modules'].length - 1;
        }
    }

    selectModule(index: number) {
        this.currentIndex = index;
    }

}

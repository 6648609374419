import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'pages/account/auth/shared/auth.guard';
import { UnauthGuard } from 'pages/account/auth/shared/unauth.guard';
import { NotFoundPage } from 'pages/misc/errors/not-found.page';
import { PermissionDeniedPage } from 'pages/misc/errors/permission-denied.page';
import { ServerErrorPage } from 'pages/misc/errors/server-error.page';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/misc/home/shared/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'welcome',
        loadChildren: () => import('./pages/account/auth/welcome/welcome.module').then((m) => m.WelcomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/account/auth/shared/auth.module').then((m) => m.AuthModule),
        canActivate: [UnauthGuard],
    },
    {
        path: 'cities',
        loadChildren: () => import('./pages/inputs/cities/shared/city.module').then((m) => m.CityModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'contract-balances',
        loadChildren: () => import('./pages/pv/contract-balances/shared/contract-balance.module').then((m) => m.ContractBalanceModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'people',
        loadChildren: () => import('./pages/inputs/people/shared/person.module').then((m) => m.PersonModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'sales-teams',
        loadChildren: () => import('./pages/pv/sales-teams/shared/sales-team.module').then((m) => m.SalesTeamModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'goals',
        loadChildren: () => import('./pages/pv/goals/shared/goals.module').then((m) => m.GoalsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'products',
        loadChildren: () => import('./pages/inputs/products/shared/product.module').then((m) => m.ProductModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'positions',
        loadChildren: () => import('./pages/inputs/positions/shared/position.module').then((m) => m.PositionModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'profiles',
        loadChildren: () => import('./pages/account/profiles/shared/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'surveys',
        loadChildren: () => import('./pages/pv/surveys/shared/survey.module').then((m) => m.SurveyModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'checkups',
        loadChildren: () => import('./pages/pv/checkups/shared/checkup.module').then((m) => m.CheckupModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'release-notes',
        loadChildren: () => import('./pages/misc/release-notes/shared/release-note.module').then((m) => m.ReleaseNoteModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'action-checklist-item-categories',
        loadChildren: () =>
            import('./pages/pv/action-checklist-item-categories/shared/action-checklist-item-category.module').then((m) => m.ActionChecklistItemCategoryModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'corrective-actions',
        loadChildren: () =>
            import('./pages/pv/corrective-actions/shared/corrective-action.module').then((m) => m.CorrectiveActionModule),
        canActivate: [AuthGuard],
    },

    {
        path: 'breakdowns',
        loadChildren: () => import('./pages/pv/breakdowns/shared/breakdown.module').then((m) => m.BreakdownModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'positions',
        loadChildren: () => import('./pages/inputs/positions/shared/position.module').then((m) => m.PositionModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'performance-goals',
        loadChildren: () => import('./pages/pv/performance-goal/shared/performance-goal.module').then((m) => m.PerformanceGoalModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/account/users/shared/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/misc/settings/shared/setting.module').then(m => m.SettingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'leads',
        loadChildren: () => import('./pages/pv/leads/shared/lead.module').then(m => m.LeadModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/pv/reports/shared/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/pv/dashboards/shared/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'thermometer',
        loadChildren: () => import('./pages/pv/thermometer/shared/thermometer.module').then((m) => m.ThermometerModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'summary',
        loadChildren: () => import('./pages/pv/summaries-screen/shared/summaries-screen.module').then((m) => m.SummariesScreenModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'asset-management',
        loadChildren: () => import('./pages/finances/asset-management/shared/asset-management.module').then((m) => m.AssetManagementModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'companies',
        loadChildren: () => import('./pages/inputs/companies/shared/company-routing.module').then((m) => m.CompanyRoutingModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'cost-centers',
        loadChildren: () => import('./pages/inputs/cost-centers/shared/cost-center.module').then((m) => m.CostCenterModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'approval-flows',
        loadChildren: () => import('./pages/approvals/approval-flows/shared/approval-flow.module').then((m) => m.ApprovalFlowModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'iptu-payment',
        loadChildren: () => import('./pages/incorporation/iptu-payment/shared/iptu-payment.module').then((m) => m.IptuPaymentModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'sca',
        loadChildren: () => import('./pages/automations/sales-contract-automation/shared/sales-contract-automation.module').then((m) => m.SalesContractAutomationModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'cpa',
        loadChildren: () => import('./pages/automations/commission-payment-automation/shared/commission-payment-automation.module').then((m) => m.CommissionPaymentAutomationModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'cia',
        loadChildren: () => import('./pages/automations/customer-income/shared/customer-income-automation.module').then((m) => m.CustomerIncomeAutomationModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'supply-chain',
        loadChildren: () => import('./pages/supplies/shared/supplies.module').then((m) => m.SuppliesModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'payment-forecasts',
        loadChildren: () => import('./pages/finances/payment-forecasts/shared/payment-forecast.module').then((m) => m.PaymentForecastModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'bills-report',
        loadChildren: () => import('./pages/finances/payable-bills/shared/payable-bill.module').then((m) => m.PayableBillModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'company-areas',
        loadChildren: () => import('./pages/inputs/company-areas/shared/company-area.module').then((m) => m.CompanyAreaModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'finances',
        loadChildren: () => import('./pages/finances/contract-approval/shared/contract-approval.module').then((m)=>m.ContractApprovalModule),
        canActivate: [AuthGuard],
    },
    {
        'path': 'health',
        loadChildren: () => import('./pages/misc/health-check/shared/health-check.module').then((m)=>m.HealthCheckModule),
    },
    {
        'path': 'competition-boards',
        loadChildren: () => import('./pages/operations/competition-boards/shared/competition-board.module').then((m)=>m.CompetitionBoardModule),
        canActivate: [AuthGuard],
    },
    {
        path: '403',
        component: PermissionDeniedPage,
        data: {
            title: '403 - Permissões Insuficientes'
        }
    },
    {
        path: '500',
        component: ServerErrorPage,
        data: {
            title: '500 - Erro Inesperado'
        }
    },
    {
        path: '**',
        component: NotFoundPage,
        data: {
            title: '404 - Página Não Encontrada'
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageService {
    private pageSource = new BehaviorSubject<any>(null);
    private eventSource = new BehaviorSubject<any>(null);
    currentPage = this.pageSource.asObservable();
    currentEvent = this.eventSource.asObservable();

    private toggleDetailsSource = new Subject<{ page: any; event: any }>();
    toggleDetails$ = this.toggleDetailsSource.asObservable();

    private componentVisibleSubject = new BehaviorSubject<boolean>(false); // Define o estado inicial (oculto)
    componentVisible$ = this.componentVisibleSubject.asObservable();

    triggerToggleDetails(page: any, event: any) {
        this.toggleDetailsSource.next({ page, event });
    }

}

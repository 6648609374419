import { Serializable } from "core/shared/serializable.model";
import { CostCenter } from "./cost-center.model";

export class CostCenterSpreadsheetVersion extends Serializable {

    public id: string = null;
    public description: string = null;
    public version: number = null;
    public reference: number = null;
    public active: boolean = null;
    public status: string = 'pending_synchronization';
    public costCenter: CostCenter = null;

    private statusInfos = {
        'pending_synchronization': {
            label: 'Sincronização pendente',
            color: 'warning',
        },
        'synchronizing': {
            label: 'Sincronizando',
            color: 'primary',
        },
        'synchronized': {
            label: 'Sincronizado',
            color: 'success',
        },
        'failed_synchronization': {
            label: 'Falhou',
            color: 'danger',
        },
    }

    constructor(data: any = {}) {
        super();
        this.serialize(data);
    }

    public get relationships() {
        return {
            costCenter: CostCenter,
        };
    }

    public get label() {
        return `${this.version} - ${this.description}`;
    }

    public get badgeLabel() {
        return this.statusInfos[this.status].label;
    }

    public get badgeColor() {
        return this.statusInfos[this.status].color;
    }

    public get isSyncDisabled() {
        return ['synchronized', 'synchronizing'].includes(this.status);
    }

    public get isSyncEnabled() {
        return ['pending_synchronization', 'failed_synchronization'].includes(this.status);
    }

    public get isSyncing() {
        return this.status === 'synchronizing';
    }

    public get isSynced() {
        return this.status === 'synchronized';
    }
}
